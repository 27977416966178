@import '../../common.scss';

.rooms_sec1 {
    padding: 15.625em 0 2.813em;
    background: $color-EAE5DF;

    .text_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 0 4.375em;

        .small_icon {
            width: 100%;
            max-width: 1.563em;
            margin: 0 0 1.25em;
        }

        .subtitle {
            @include textStyles(1.25em, 1.4em, 0);
            color: $color-603413;
        }

        .title {
            @include textStyles(5em, 1.225em, 0);
            font-family: $KF;
            margin: 0.138em 0 0;
        }

    }

    .banner {
        .banner_img {
            width: 100%;
            max-width: 100%;
        }
    }
}

.rooms_sec2 {
    padding: 2.5em 0 6.25em;
    background: $color-EAE5DF;

    .text_container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            @include textStyles(5em, 1.225em, 0);
            font-family: $KF;
        }

        .desc {
            @include textStyles(1.25em, 1.4em, 0);
            max-width: 75%;
            margin: 1.35em auto 2.8em;
        }
    }

    .amenity_flex {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 100%;

        .amenity_box {
            width: 100%;
            max-width: 32%;
            height: 100%;
            margin: 0 0 4em;

            .img_wrapper {
                display: block;
                overflow: hidden;

                .am_img {
                    width: 100%;
                    max-width: 100%;
                    transition: all 0.3s;
                }

                &:hover {
                    .am_img {
                        transform: scale(1.1);
                    }
                }
            }

            .text_container {
                display: block;
                margin: 1.438em 0 0;

                .title {
                    @include textStyles(2em, 0.875em, 0);
                    font-family: $KF;
                    text-align: left;
                }

                .desc {
                    @include textStyles(1.25em, 1.4em, 0);
                    max-width: 100%;
                    margin: 0.6em 0 0;
                    text-align: left;
                }
            }
        }
    }
}

.rooms_sec3 {
    position: relative;
    overflow: hidden;
    padding: 7.25em 0 10.75em;
    color: $color-ffffff;

    .bg_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .text_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 5.313em;

        .title {
            @include textStyles(5em, 1.225em, 0);
            font-family: $KF;
        }

        .desc {
            @include textStyles(1.25em, 1.4em, 0);
            max-width: 30.6em;
        }
    }

    .activity_slider {
        .swiper-slide {
            position: relative;

            .ac_img_wrap {
                position: relative;

                &::before {
                    position: absolute;
                    content: '';
                    background: rgba($color: $color-000000, $alpha: 0.5);
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    transition: all 1s;
                    pointer-events: none;
                }
            }
        }

        .swiper-slide-active {
            .ac_img_wrap {
                &::before {
                    opacity: 0;
                }
            }
        }

        .activity_box {
            .ac_img {
                width: 100%
            }

            .ac_text {
                .count {
                    @include textStyles(1.25em, 1.4em, 0);
                    margin: 0 0 1em
                }

                .title {
                    @include textStyles(2.5em, 1em, 0);
                    font-family: $KF;
                }

                &.ac_first {
                    margin: 0 0 2.875em;
                }

                &.ac_second {
                    margin: 1.125em 0 0;
                }
            }
        }
    }
}

.rooms_sec4 {
    padding: 10.688em 0 5.313em;
    background: $color-EAE5DF;

    .text_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 6.25em;

        .title {
            @include textStyles(5em, 1.225em, 0);
            font-family: $KF;
            max-width: 10.7em
        }

        .desc {
            @include textStyles(1.25em, 1.4em, 0);
            max-width: 30.6em;
        }
    }

    .vac_flex {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .vac_box {
            width: 100%;
            max-width: 18%;

            .vac_icon {
                width: 100%;
                max-width: 4em;
                margin: 0 0 1.875em;
            }

            .title {
                @include textStyles(2em, 0.875em, 0);
            }
        }
    }
}

.rooms_sec5 {
    padding: 5.313em 0 10.625em;
    overflow: hidden;
    background: $color-EAE5DF;

    .title {
        @include textStyles(5em, 1.225em, 0);
        font-family: $KF;
    }

    .gallery_slider {
        margin: 0 -6.22% 0 0;
        padding: 0 3.11% 0 0;

        .swiper {
            padding: 1.875em 0;

            .img_wrapper {
                overflow: hidden;

                .gallery_img {
                    width: 100%;
                    transition: all 0.3s;
                }

                &:hover {
                    .gallery_img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        .nav_progress {
            display: flex;
            align-items: center;

            .nav_wrap {
                display: flex;
                margin: 0 2em 0 0;

                .icon_wrap {
                    &:first-child {
                        margin: 0 1em 0 0;
                    }

                    .nav_icon {
                        width: 100%;
                        max-width: 5.25em;
                        cursor: pointer;
                    }
                }
            }

            .custom_pagination {
                position: relative;
                max-width: 50%;
                z-index: 0;

                .swiper-pagination-progressbar-fill {
                    background: $color-311806;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .rooms_sec1 {
        .text_container {
            .subtitle {
                font-size: 1.6em;
            }

            .title {
                font-size: 4em;
            }
        }
    }

    .rooms_sec2 {
        .text_container {
            .title {
                font-size: 4em;
            }

            .desc {
                max-width: 70%;
                text-align: center;
                font-size: 1.6em;
            }
        }

        .amenity_flex {
            .amenity_box {
                .text_container {
                    .desc {
                        font-size: 1.6em;
                    }
                }
            }
        }
    }

    .rooms_sec3 {
        .text_flex {
            .title {
                font-size: 4em;
            }

            .desc {
                font-size: 1.6em;
            }
        }

        .activity_slider {
            padding: 0 0 0 4em;

            .activity_box {
                .ac_text {
                    .count {
                        font-size: 2em;
                    }

                    .title {
                        font-size: 2.4em;
                    }
                }
            }
        }
    }

    .rooms_sec4 {
        .text_flex {
            flex-direction: column;
            align-items: flex-start;

            .title {
                font-size: 4em;
            }

            .desc {
                font-size: 1.6em;
                max-width: 70%;
                margin: 1em 0 0;
            }
        }
    }

    .rooms_sec5 {
        .title {
            font-size: 4em;
        }
    }
}

@media screen and (max-width: 768px) {
    .rooms_sec2 {
        .amenity_flex {
            .amenity_box {
                max-width: 48%;
            }
        }
    }

    .rooms_sec3 {
        .text_flex {
            flex-direction: column;
            align-items: flex-start;

            .desc {
                max-width: 70%;
                margin: 1em 0 0;
            }
        }
    }

    .rooms_sec4 {
        padding: 10.688em 0 2em;

        .vac_flex {
            flex-wrap: wrap;
            justify-content: flex-start;

            .vac_box {
                max-width: 33.33333%;
                padding: 0 1em 2.5em 0;

                .vac_icon {
                    max-width: 6.4em;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .rooms_sec1 {
        padding: 14.5em 0 2.8em;

        .text_container {
            .small_icon {
                margin: 0 0 1.1em;
            }

            .subtitle {
                font-size: 1.4em;
            }

            .title {
                font-size: 3.6em;
                margin: 0.167em 0 0;
            }
        }

    }

    .rooms_sec2 {
        padding: 2.5em 0 3.2em;

        .text_container {
            .title {
                font-size: 3.6em;
            }

            .desc {
                font-size: 1.4em;
                max-width: 95%;
                margin: 1.214em 0 2.357em;
            }
        }

        .amenity_flex {
            .amenity_box {
                max-width: 100%;
                margin: 0 0 4.2em;

                .text_container {
                    margin: 1em 0 0;

                    .desc {
                        font-size: 1.4em;
                        margin: 0.286em 0 0;
                    }
                }
            }
        }
    }

    .rooms_sec3 {
        padding: 7.4em 0;

        .text_flex {
            margin: 0 0 5.5em;

            .desc {
                font-size: 1.4em;
                max-width: 100%;
                margin: 1.214em 0 0;
            }
        }

        .activity_slider {
            padding: 0 0 0 1.5em;

            .activity_box {
                .ac_text {
                    &.ac_first {
                        margin: 0 0 1.5em;
                    }

                    &.ac_second {
                        margin: 0.9em 0 0;
                    }

                    .count {
                        margin: 0 0 0.15em;
                    }
                }
            }
        }
    }

    .rooms_sec4 {
        padding: 7.4em 0 1.2em;

        .text_flex {
            margin: 0 0 3em;

            .desc {
                font-size: 1.4em;
                max-width: 100%;
                margin: 1.214em 0 0;
            }
        }

        .vac_flex {
            .vac_box {
                padding: 0 0 2.9em 0;

                .vac_icon {
                    max-width: 6.4em;
                    margin: 0 0 1.5em;
                }

                .title {
                    font-size: 1.4em;
                }
            }
        }
    }

    .rooms_sec5 {
        padding: 3.7em 0 7.4em;

        .gallery_slider {
            .swiper {
                padding: 2.3em 0 2.6em;
            }

            .nav_progress {
                .nav_wrap {
                    width: 100%;
                    max-width: fit-content;
                }

                .custom_pagination {
                    max-width: 100%;
                    margin: 0 1.5em 0 0;
                }
            }
        }
    }
}