@import '../../common.scss';

.header_sec {
    width: 100%;
    position: relative;
    z-index: 4;

    &.sticky {
        position: sticky;
        top: 0;
        left: 0;
    }

    .site_header {
        width: 100%;
        position: fixed;
        top: 0;
        background: transparent;
        border-bottom: 1px solid rgba($color: $color-ffffff, $alpha: 0.1);
        transition: all 0.3s;

        .header_flex {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2.281em 0;
            transition: all 0.3s;

            .navigation {
                width: 100%;
                max-width: 33.3333%;

                .navlist {
                    display: flex;
                    align-items: center;



                    .list-item {
                        display: block;
                        position: relative;
                        @include textStyles(1.125em, 1.222em, 0);
                        color: $color-ffffff;
                        font-weight: 300;
                        text-decoration: none;
                        margin: 0 1.444em 0 0;
                        padding: 1.5em 0;
                        transition: all 0.3s;

                        &.active {
                            font-weight: 600;

                            &::before {
                                content: '';
                                width: 0.5em;
                                height: 0.5em;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                                background: $color-A67B5B;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }

            .logo_wrap {
                width: 100%;
                max-width: 33.3333%;
                display: flex;
                justify-content: center;

                .logo {
                    width: 100%;
                    max-width: 11.125em;
                }
            }

            .cta_wrapper {
                width: 100%;
                max-width: 33.3333%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .language-switcher {
                    .language_drop {
                        .language_select {
                            border: none;
                            background-color: rgba(255, 255, 255, 0.2);
                            backdrop-filter: blur(0.5em);
                            -webkit-backdrop-filter: blur(0.5em);
                        }
                    }
                }
            }
        }

        &.glassmorph {
            background-color: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(0.5em);
            -webkit-backdrop-filter: blur(0.5em);

            .header_flex {
                padding: 1em 0;
            }
        }

        &.solid {
            background: $color-ffffff;

            .navigation {
                .navlist {
                    .list-item {
                        color: $color-311806;
                    }
                }
            }

            .cta_wrapper {
                .language-switcher {
                    .language_drop {
                        .language_select {
                            border: 1px solid $color-000000;
                            background-color: transparent;
                            backdrop-filter: unset;
                            -webkit-backdrop-filter: unset;
                        }
                    }
                }

                .book_cta {
                    background: $color-0A6365;
                }
            }
        }
    }

    .menu-box {
        position: fixed;
        top: 0;
        left: -33.25%;
        width: 33.25%;
        height: 100vh;
        transition: left 1s;
        // overflow: hidden;
        z-index: 1;

        .menu-inner {
            height: 100%;
            padding: 3.75em;
            background: $color-ffffff;

            .short_navlist {
                padding: 3.75em 0;

                .eyebrow {
                    @include textStyles(1.25em, 1.7em, 0);
                    font-weight: 300;
                    color: $color-311806;
                    margin: 0 0 1.5em;
                }

                .list-item {
                    display: block;
                    position: relative;
                    @include textStyles(3.75em, 1em, 0);
                    font-family: $KF;
                    color: $color-311806;
                    text-decoration: none;
                    margin: 0 0 0.75em;

                    &:last-child {
                        margin: 0;
                    }

                    &.active {
                        &::before {
                            content: "";
                            width: 0.15em;
                            height: 0.15em;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            margin: auto 0;
                            background: #A67B5B;
                            border-radius: 50%;
                        }
                    }

                    &:hover {
                        &::after {
                            content: "";
                            width: 0.15em;
                            height: 0.15em;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            margin: auto 0;
                            background: #A67B5B;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }

        .page_img_wrapper {
            width: 100%;
            height: 100%;
            max-width: 25em;
            position: absolute;
            top: 0;
            left: calc(100% - 25em);
            transition: all 1s;
            z-index: -1;

            .page_img {
                width: 100%;
                height: 100%;
            }

            &.active {
                left: 100%;
            }
        }

        &.active {
            left: 0;
        }
    }

    .outside {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(0.5em);
        -webkit-backdrop-filter: blur(0.5em);
    }

    .hamburger {
        position: relative;
        height: 1.5em;
        width: 2.5em;
        margin: 0 3.75em 0 0;


        &:hover {
            cursor: pointer;
        }

        span {
            background: white;
            transform: translateY(-50%) rotate(0deg);
            position: absolute;
            width: 100%;
            height: 10%;
            transition: top 0.1s ease 0.3s, transform 0.3s ease, background 0.1s ease 0.3s;
        }

        span:nth-child(1) {
            top: 10%;
        }

        span:nth-child(2) {
            top: 50%;
        }

        span:nth-child(3) {
            top: 90%;
            width: 40%;
        }

        &.active {
            span {
                width: 100%;
                transition: top 0.1s ease, transform 0.3s ease 0.1s, background 0.1s ease;
            }

            span:nth-child(1) {
                top: 50%;
                transform: translateY(-50%) rotate(315deg);
            }

            span:nth-child(2) {
                transform: translateY(-50%) rotate(405deg);
            }

            span:nth-child(3) {
                top: 50%;
                transform: translateY(-50%) rotate(405deg);
            }
        }

        &.black {
            span {
                background: $color-311806;
            }
        }
    }

    .booking_header {
        width: 100%;
        position: relative;
        // top: 0;
        // left: 0;
        background: $color-ffffff;
        z-index: 3;

        .booking_flex {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2.281em 1.625em;

            .logo_wrap {
                .logo {
                    width: 100%;
                    max-width: 11.125em;
                }
            }

            .language-switcher {
                position: absolute;
                right: 7.5em;
            }
        }

        .close_wrap {
            width: 100%;
            max-width: 8.063em;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid $color-311806;
            cursor: pointer;

            .close_icon {
                width: 100%;
                height: fit-content;
                max-width: 1.063em;
            }
        }

        &.sticky {
            position: fixed;
            top: 0;
            left: 0;
        }
    }
}

@media screen and (max-width: 992px) {
    .header_sec {
        .site_header {
            .header_flex {
                .navigation {
                    .navlist {
                        .list-item {
                            display: none;
                        }
                    }
                }
            }
        }

        .menu-box {
            width: 50%;
            left: -50%;

            .menu-inner {
                padding: 3em;
            }
        }

        .hamburger {
            height: 1.75em;
        }
    }
}

@media screen and (max-width: 768px) {
    .header_sec {
        .site_header {
            .header_flex {
                .logo_wrap {
                    .logo {
                        max-width: 8.1em;
                    }
                }

                .cta_wrapper {
                    .language-switcher {
                        .language_drop {
                            .language_select {
                                width: 1.6em;
                                padding: 0;
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }

        .booking_header {
            .booking_flex {
                // justify-content: flex-start;
                justify-content: space-between;
                padding: 1.8em 0;

                .logo_wrap {
                    .logo {
                        max-width: 8.1em;
                    }
                }

                .language-switcher {
                    position: relative;
                    right: auto;
                    margin: 0 6.6em 0 0;
                }
            }

            .close_wrap {
                max-width: 6.6em;

                .close_icon {
                    width: 100%;
                    height: fit-content;
                    max-width: 1.3em;
                }
            }
        }
    }
}

@media screen and (max-width:600px) {
    .header_sec {
        .menu-box {
            width: 100%;
            left: -100%;

            .menu-inner {
                .short_navlist {
                    .eyebrow {
                        font-size: 1.2em;
                    }

                    .list-item {
                        font-size: 3.8em;
                    }
                }
            }
        }
    }
}