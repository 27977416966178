@import '../../common.scss';

.home_sec1 {
    position: relative;
    // max-height: 100vh;
    overflow: hidden;
    background: $color-EAE5DF;

    .banner_video {
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
        // max-height: 100vh;
        pointer-events: none;
        object-fit: cover;
    }

    .overlay {
        display: block;
        position: absolute;
        background: rgba($color: $color-000000, $alpha: 0.4);
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .banner_text {
        width: 100%;
        height: 100%;
        max-height: calc(100% - 5em);
        position: absolute;
        top: 5em;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .banner_title {
            @include textStyles(6em, 1.3em, 0);
            font-family: $KF;
            color: $color-ffffff;
            text-align: center;
        }

        .verticle {
            width: 1px;
            max-width: 4.875em;
            margin: 2em 0;
            opacity: 0.5;
        }
    }
}

.home_sec2 {
    background: $color-EAE5DF;
    padding: 10.625em 0 2.875em;

    .about_grid {
        width: 100%;
        display: grid;
        grid-template-columns: 25% 50% 25%;
        padding: 0 0 6.25em;

        .image_col1 {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .small_img {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .about_1 {
                    width: 100%;
                    max-width: 16em;
                    margin: 0 -30% 5.625em 0;
                }
            }

            .about_2 {
                width: 100%;
                max-width: 27.5em;
                margin: 5.625em 0 0;
            }
        }

        .image_col2 {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;

            .about_3 {
                width: 100%;
                max-width: 27.5em;
                margin: 0 0 5.625em;
            }

            .small_img {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .about_4 {
                    width: 100%;
                    max-width: 16em;
                    margin: 5.625em 0 0;
                }
            }
        }

        .center_col {
            display: flex;
            align-items: center;

            .text_container {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin: 4em 0 0;

                .about_icon {
                    width: 100%;
                    max-width: 1.563em;
                    margin: 0 0 3.75em;
                }

                .title {
                    @include textStyles(5em, 1.3em, 0);
                    font-family: $KF;
                    max-width: 90%
                }

                .desc {
                    @include textStyles(1.25em, 1.4em, 0);
                    max-width: 75%;
                    margin: 3.5em auto 2em;
                }
            }
        }
    }

    .peace_wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after,
        &::before {
            width: 100%;
            content: "";
            display: block;
            border-bottom: 1px solid rgba($color: $color-000000, $alpha: 0.4);
        }

        .star_icon {
            width: 100%;
            max-width: 3.25em;
            margin: 0 1em;
        }
    }

    .peace_content {
        .text_container {
            max-width: 62.5%;
            margin: 2em auto 0;
            text-align: center;

            .title {
                @include textStyles(1.25em, 1.4em, 0);
                margin: 0 0 0.8em;
                color: $color-603413
            }

            .desc {
                @include textStyles(1.25em, 1.4em, 0);
            }
        }
    }
}

.home_sec3 {
    position: relative;
    pointer-events: none;
    background: $color-EAE5DF;

    .bg_wrapper {
        position: relative;

        .bg_slider {
            position: relative;
            z-index: 0;

            .bg_img {
                width: 100%;
                height: 100%;
            }
        }

        .title_wrapper {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;

            .title_slider {
                max-width: 100%;
                margin: -15em auto 0;
                padding: 2.5em;
                overflow: visible;

                // .title_slide {
                //     max-width: fit-content;
                // }

                .amenity_title {
                    color: $color-ffffff;
                    text-align: center;
                    @include textStyles(8.875em, 1.2em, 0);
                    font-family: $KF;
                }
            }
        }
    }

    .thumb_wrapper {
        margin: -20em auto 0;

        .thumb_slider {
            width: 100%;
            height: 100%;
            max-width: 21.25em;

            .thumb_img {
                width: 100%;
                height: 100%;
                max-width: 21.25em;
                aspect-ratio: 113/146;
                object-fit: contain;
            }
        }
    }
}

.home_sec4 {
    padding: 10.625em 0;
    background: $color-EAE5DF;

    .image_text_grid {
        width: 100%;
        max-width: 81%;
        margin: 0 auto;
        display: flex;
        align-items: center;

        .img_box {
            display: block;
            width: 100%;
            max-width: 45%;

            .feature_img {
                width: 100%;
                height: 100%;
                aspect-ratio: 16/21;
            }
        }

        .content_box {
            display: block;
            width: 100%;
            max-width: 55%;
            padding: 0 3.125em 0 10.125em;

            .text_container {
                .subtitle {
                    @include textStyles(1.25em, 1.4em, 0);
                    color: $color-603413;
                    text-transform: uppercase
                }

                .title {
                    @include textStyles(5em, 1.225em, 0);
                    font-family: $KF;
                    margin: 0.138em 0 0.35em;
                }

                .desc {
                    max-width: 87.5%;
                    @include textStyles(1.25em, 1.4em, 0);
                }
            }
        }

        &.reverse {
            .img_box {
                max-width: 55%;
                padding: 0 0 0 10.125em;

                .feature_img {
                    aspect-ratio: 1282/1710;
                }
            }

            .content_box {
                max-width: 45%;
                padding: 0 5.688em 0 0;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .home_sec2 {
        padding: 10em 0 5em;

        .about_grid {
            .image_col1 {
                .small_img {
                    .about_1 {
                        margin: 0 0 5.625em 0;
                    }
                }
            }

            .center_col {
                .text_container {
                    .title {
                        font-size: 4em;
                    }

                    .desc {
                        font-size: 1.6em;
                        max-width: 90%;
                    }
                }
            }
        }

        .peace_content {
            .text_container {

                .title,
                .desc {
                    font-size: 1.6em;
                }
            }
        }
    }

    .home_sec3 {
        .bg_wrapper {
            .title_wrapper {
                .title_slider {
                    .amenity_title {
                        font-size: 8em;
                    }
                }
            }
        }
    }

    .home_sec4 {
        .image_text_grid {
            max-width: 100%;

            .content_box {
                .text_container {

                    .subtitle,
                    .desc {
                        font-size: 1.6em;
                    }

                    .title {
                        font-size: 4em;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .home_sec3 {
        .bg_wrapper {
            .title_wrapper {
                .title_slider {
                    .amenity_title {
                        font-size: 6em;
                    }
                }
            }
        }

        .thumb_wrapper {
            margin: -15em auto 0;

            .thumb_slider {
                max-width: 15em;
            }
        }
    }

    .home_sec4 {
        .image_text_grid {
            .content_box {
                padding: 2.5em 0 2.5em 2.5em;

                .text_container {
                    .desc {
                        max-width: 100%;
                    }
                }
            }

            &.reverse {
                .img_box {
                    max-width: 45%;
                    padding: 0;
                }

                .content_box {
                    max-width: 55%;
                    padding: 2.5em 2.5em 2.5em 0;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {

    .home_sec1 {
        .banner_video {
            min-height: 100vh;
        }

        .banner_text {
            .banner_title {
                font-size: 4em;
            }
        }
    }

    .home_sec2 {
        padding: 6em 0;

        .about_grid {
            grid-template-columns: 1fr;
            padding: 0 0 5em;

            .image_col1 {
                display: flex;
                flex-direction: row;
                align-items: center;

                .small_img {
                    display: block;
                    max-width: 47.5%;
                    padding: 0 4em 0 0;

                    .about_1 {
                        max-width: 100%;
                        margin: 0;
                    }
                }

                .about_2 {
                    max-width: 52.5%;
                    margin: 0;
                }
            }

            .center_col {
                .text_container {
                    margin: 5em 0;

                    .about_icon {
                        margin: 0 0 1.5em;
                    }

                    .title {
                        font-size: 3.5em;
                    }

                    .desc {
                        max-width: 100%;
                        margin: 2.5em auto;
                    }
                }
            }

            .image_col2 {
                display: flex;
                flex-direction: row;
                align-items: center;

                .small_img {
                    display: block;
                    max-width: 47.5%;
                    padding: 0 0 0 4em;

                    .about_4 {
                        max-width: 100%;
                        margin: 0;
                    }
                }

                .about_3 {
                    max-width: 52.5%;
                    margin: 0;
                }
            }
        }

        .peace_content {
            .text_container {
                max-width: 100%;
                margin: 4em auto 0;
            }
        }
    }

    .home_sec3 {
        .bg_wrapper {
            .bg_slider {
                .bg_img {
                    min-height: 50em;
                }
            }

            .title_wrapper {
                .title_slider {
                    margin: -10em auto 0;

                    .amenity_title {
                        font-size: 5em;
                    }
                }
            }
        }
    }

    .home_sec4 {
        padding: 6em 0;

        .image_text_grid {
            flex-direction: column;

            .img_box {
                max-width: 100%;
            }

            .content_box {
                max-width: 100%;
                padding: 5em 0;
            }

            &.reverse {
                flex-direction: column-reverse;

                .img_box {
                    max-width: 100%;
                }

                .content_box {
                    max-width: 100%;
                    padding: 5em 0 0;
                }
            }
        }
    }
}