@import '../../common.scss';

.loc_sec1 {
    padding: 20em 0 5.313em;
    background: $color-EAE5DF;

    .loc_banner_flex {
        display: flex;
        align-items: center;

        .text_container {
            width: 100%;
            max-width: 66%;
            padding: 0 8.313em 0 0;

            .subtitle {
                @include textStyles(1.25em, 1.4em, 0);
                color: $color-603413;
            }

            .title {
                @include textStyles(6.25em, 1.25em, 0);
                font-family: $KF;
                margin: 0.1em 0 0.47em;
            }

            .sub_desc {
                @include textStyles(2em, 1.188em, 0);
            }

            .desc {
                max-width: 39.3em;
                @include textStyles(1.25em, 1.4em, 0);
                margin: 0.3em 0 1.75em;
            }

            .animated-cta {
                margin: 0;
            }
        }

        .map_container {
            width: 100%;
            max-width: 44%;
            aspect-ratio: 1/1;
            background: $color-ffffff;
            border-radius: 2.5em;
            overflow: hidden;

            .map_frame {
                border: 0;

                &:active,
                &:hover {
                    border: 0;
                }
            }
        }
    }
}

.loc_sec2 {
    padding: 5.313em 0 10.625em;
    background: $color-EAE5DF;

    .title {
        @include textStyles(6.25em, 1.25em, 0);
        font-family: $KF;
    }

    .beauty_flex {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 100%;
        padding: 4.188em 0 6.625em;

        .beauty_box {
            width: 100%;
            max-width: 32%;
            height: 100%;
            margin: 0 0 4em;

            .img_wrapper {
                display: block;
                overflow: hidden;

                .am_img {
                    width: 100%;
                    max-width: 100%;
                    transition: all 0.3s;
                }

                &:hover {
                    .am_img {
                        transform: scale(1.1);
                    }
                }
            }

            .text_container {
                display: block;
                margin: 1.438em 0 0;

                .title {
                    @include textStyles(2em, 0.875em, 0);
                    font-family: $KF;
                    text-align: left;
                }

                .desc {
                    @include textStyles(1.25em, 1.4em, 0);
                    max-width: 100%;
                    margin: 0.6em 0 0;
                    text-align: left;
                }
            }
        }
    }

    .invite_container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .invite_title {
            @include textStyles(6.25em, 1.25em, 0);
            max-width: 90%;
            margin: 0 auto;
            font-family: $KF;
            text-align: center;
        }

        .verticle_line {
            height: 87px;
            margin: 0.313em 0 1.875em;
        }
    }
}

@media screen and (max-width: 1024px) {
    .loc_sec1 {
        .loc_banner_flex {
            .text_container {
                padding: 0 2.5em 0 0;

                .subtitle {
                    font-size: 1.6em;
                }

                .title {
                    font-size: 4em;
                }

                .sub_desc {
                    font-size: 2.4em;
                }

                .desc {
                    font-size: 1.6em;
                }
            }
        }
    }

    .loc_sec2 {
        .title {
            font-size: 5em;
        }

        .beauty_flex {
            .beauty_box {
                .text_container {
                    .desc {
                        font-size: 1.6em;
                    }
                }
            }
        }

        .invite_container {
            .invite_title {
                font-size: 5em;
                max-width: 100%;
            }

            .verticle_line {
                margin: 1em 0 3em;
            }
        }
    }
}

@media screen and (max-width: 786px) {
    .loc_sec2 {
        .beauty_flex {
            .beauty_box {
                max-width: 48%;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .loc_sec1 {
        padding: 19.2em 0 3.5em;

        .loc_banner_flex {
            flex-direction: column;

            .text_container {
                max-width: 100%;
                padding: 0 0 6em;

                .subtitle {
                    font-size: 1.4em;
                }

                .title {
                    font-size: 3.6em;
                    margin: 0.168em 0 0.612em;
                }

                .sub_desc {
                    font-size: 2.2em;
                }

                .desc {
                    font-size: 1.4em;
                    margin: 0.571em 0 2.571em;
                }
            }

            .map_container {
                max-width: 100%;
                border-radius: 4.1em;
            }
        }
    }

    .loc_sec2 {
        padding: 3.5em 0 7.4em;

        .title {
            font-size: 3.6em;
        }

        .beauty_flex {
            padding: 2.3em 0 4.4em;

            .beauty_box {
                max-width: 100%;
                margin: 0 0 2.6em;

                .text_container {
                    margin: 1em 0 0;

                    .desc {
                        font-size: 1.4em;
                        margin: 0.286em 0 0;
                    }
                }
            }
        }

        .invite_container {
            .invite_title {
                font-size: 3.6em;
            }

            .verticle_line {
                margin: 2.5em 0 2em;
            }
        }
    }
}