@import '../../common.scss';

.failed {
    padding: 15em 0 5em;
    background: $color-EAE5DF;
}

.failed_sec {
    width: 100%;
    max-width: 71.313em;
    padding: 5em 9.375em;
    background: $color-ffffff;
    border-radius: 0.625em;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .failed_icon {
        width: 100%;
        max-width: 6.25em;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .title {
        @include textStyles(2.5em, 3.125em, 0);
        font-family: $KF;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .caption {
        @include textStyles(1.25em, 1.4em, 0);
        margin: 0 0 2.1em;

        &.caption2 {
            margin: 0;
        }

        a {
            color: initial;
            text-decoration: underline;
        }
    }

    .cta_wrapper {
        padding: 2.5em 0 0;
    }
}

@media screen and (max-width: 992px) {
    .failed_sec {
        max-width: 100%;
        padding: 5em;
    }
}

@media screen and (max-width: 600px) {
    .failed_sec {
        max-width: 100%;
        padding: 5em 2em;

        .failed_icon {
            max-width: 6.3em;
        }

        .title {
            @include textStyles(2.4em, 1.25em, 0);
            text-align: center;
            margin: 0.458em auto 1.083em;
        }

        .caption {
            max-width: 85%;
            @include textStyles(1.4em, 1.571em, 0);
            margin: 0 auto 1.429em;
            text-align: center;
        }
    }
}