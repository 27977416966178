@import '../../common.scss';

.booking_sec {
    position: relative;
    // min-height: 86vh;
    // user-select: none;
    // -webkit-user-select: none;
    // -moz-user-select: none;

    .count_container {
        // width: 100%;
        position: sticky;
        background: $color-ffffff;
        border-top: 1px solid $color-311806;
        top: 0;
        // top: 0;
        z-index: 2;

        .steps_strip {
            position: relative;
            max-width: 55.875em;
            margin: 0 auto;
            padding: 2em 0;
            display: flex;
            justify-content: space-between;

            .line_through {
                width: 100%;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                pointer-events: none;
            }

            .step_wrap {
                display: flex;
                flex-direction: column;
                align-items: center;

                &.active {
                    .count {
                        background: $color-0A6365;
                        color: $color-ffffff;
                    }

                    .title {
                        font-weight: bold;
                    }
                }

                .count {
                    width: 2.5em;
                    height: 2.5em;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $color-EFEFF5;
                    color: $color-311806;
                    transition: all 0.3s;
                    margin: 0 0 0.375em;

                    .done_icon {
                        width: 100%;
                        max-width: 1em
                    }
                }

                .title {
                    @include textStyles(1.125em, 1.222em, 0);
                }
            }
        }
    }

    .steps_container {

        // padding: 7.813em 0;
        .book_container {
            position: relative;
            background: $color-EAE5DF;

            .form_bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
                z-index: 0;
            }

            .step1_sec {
                padding: 7.813em 0;
                position: relative;
                z-index: 1;

                .step1_form {
                    width: 100%;
                    max-width: 34.813em;
                    margin: 7.813em auto;
                    padding: 2.813em 2em 4.688em;
                    background: $color-ffffff;
                    border-radius: 0.625em;

                    .form_field {
                        .form_title {
                            padding: 0 0 0.6em;
                        }


                        .range_picker {
                            width: 100%;
                            height: 3.75em;
                            font-size: 1em;
                            border: 1px solid $color-311806;
                            padding: 1.25em;

                            input {
                                @include textStyles(1.25em, 1.1em, 0);
                                text-align: center;

                                &::placeholder {
                                    color: $color-311806;
                                }
                            }

                            .ant-picker-range-separator {
                                margin: 0 1em;
                            }

                            .anticon svg {
                                width: 1.25em;
                                height: 1.25em;
                                fill: $color-000000;
                            }
                        }

                        .adult_counter {
                            height: 3.75em;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border: 1px solid $color-311806;
                            // padding: 1.25em;
                            user-select: none;
                            -webkit-user-select: none;
                            -moz-user-select: none;

                            .adult_input {
                                text-align: center;
                                border: 0;
                            }

                            .countdown,
                            .countup {
                                position: relative;
                                width: 6em;
                                height: 100%;
                                cursor: pointer;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                span {
                                    font-size: 2.5em;
                                    line-height: 0;
                                    font-weight: 200;
                                }
                            }

                            .countup {
                                span {
                                    margin: -0.1em 0 0;
                                }
                            }
                        }
                    }

                    .conditions_wrap {
                        padding: 2em 0 0;

                        .cbox {
                            .title {
                                @include textStyles(1.25em, 1.1em, 0);
                                font-weight: bold;
                                margin: 0 0 0.3em
                            }

                            .caption {
                                @include textStyles(1.25em, 1.1em, 0);
                                margin: 1em 0 0;

                                .anchor {
                                    border-bottom: 1px solid rgba($color: $color-000000, $alpha: 1);
                                }
                            }
                        }
                    }
                }
            }

            .step2_sec {
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 2.125em 0 2.875em;

                .step2_left,
                .step2_right {
                    display: block;
                    width: 100%;
                    max-width: 36em;
                    padding: 0 0.563em;

                    .sldates_wrap,
                    .rates_wrap,
                    .conditions_wrap {
                        width: 100%;
                        max-width: 34.813em;
                        padding: 1.875em 2em;
                        background: $color-ffffff;
                        border-radius: 0.625em;
                    }


                    .sldates_wrap {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .sl_details {
                            .sl_dates {
                                padding: 0 0 1.375em;
                            }

                            .sl_dates,
                            .sl_adult {
                                .title {
                                    @include textStyles(1.25em, 1.1em, 0);
                                    font-weight: bold;
                                    margin: 0 0 0.3em
                                }

                                .dates,
                                .adult {

                                    @include textStyles(1.25em, 1.1em, 0);
                                }
                            }
                        }

                        .sl_edit {
                            .edit_btn {
                                border: 1px solid $color-311806;
                                outline: none;
                                background: none;
                                border-radius: 0.25em;
                                @include textStyles(1.25em, 1.1em, 0);
                                padding: 1em 1.6em;
                                cursor: pointer;
                                transition: all 0.3s;

                                &:hover {
                                    background: $color-0A6365;
                                    color: $color-ffffff;
                                }
                            }
                        }
                    }

                    .rates_wrap {
                        margin: 1.25em 0 0;
                        padding: 1.875em 2em 3.438em;

                        .title {
                            @include textStyles(1.25em, 1.1em, 0);
                            font-weight: bold;
                            margin: 0 0 0.3em
                        }

                        .note {
                            @include textStyles(1.25em, 1.1em, 0);
                        }

                        .ad_bal_flex {
                            display: flex;
                            justify-content: space-between;
                            padding: 2.5em 0;

                            .rate_ad {
                                width: 100%;
                                max-width: 50%;
                                @include textStyles(2em, 1em, 0);
                                font-weight: 500;
                            }
                        }

                        .rate {
                            @include textStyles(2.5em, 1em, 0);
                            font-weight: 700;
                            padding: 0 0 0.8em;

                        }

                    }

                    .conditions_wrap {
                        padding: 2em;

                        .cbox {
                            padding: 0 0 1.75em;

                            &:last-child {
                                padding: 0;
                            }

                            .title {
                                @include textStyles(1.25em, 1.1em, 0);
                                font-weight: bold;
                                margin: 0 0 0.3em
                            }

                            .caption {
                                @include textStyles(1.25em, 1.1em, 0);
                            }
                        }
                    }
                }
            }

            .step3_sec {
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 5em 0;

                .contact_wrap {
                    width: 100%;
                    max-width: 71.25em;
                    padding: 3.125em 2em;
                    background: $color-ffffff;
                    border-radius: 0.625em;

                    .title {
                        font-family: $KF;
                        @include textStyles(2em, 1.4em, 0);
                        margin: 0 0 1.25em;
                    }

                    .form_field {
                        .form_title {
                            margin: 0 0 0.5em;
                        }
                    }

                    input[type=number]::-webkit-inner-spin-button,
                    input[type=number]::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        margin: 0;
                    }

                    .pay_btn {
                        max-width: 22.813em;
                        border-radius: 68px;
                    }

                    .error_message {
                        position: relative;
                        bottom: auto;
                    }
                }
            }

            .step4_sec {
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 5em 0;
            }
        }
    }
}

@media screen and (max-width:768px) {

    .ant-picker-dropdown {
        top: 100px !important;

    }

    .ant-picker-panels {
        flex-direction: column !important;
        top: 0 !important;
    }
}

@media screen and (max-width: 600px) {
    .booking_sec {
        .steps_container {
            .book_container {
                .step1_sec {
                    .step1_form {
                        max-width: 100%;

                        .form_field {
                            .form_title {
                                padding: 0 0 0.714em;
                            }
                        }

                        .conditions_wrap {
                            max-width: 100%;
                        }
                    }
                }

                .step2_sec {
                    flex-direction: column;
                    // padding: 3.1em 0 3.8em;
                    padding: 4em 0;

                    .step2_left,
                    .step2_right {
                        padding: 0;
                        max-width: 100%;

                        .sldates_wrap,
                        .rates_wrap,
                        .conditions_wrap {
                            max-width: 100%;
                        }

                        .rates_wrap {
                            margin: 1.6em 0;
                        }
                    }
                }

                .step3_sec {
                    // padding: 3.1em 0 4.1em;
                    padding: 4em 0;
                }

                .step4_sec {
                    // padding: 3.1em 0 2.8em;
                    padding: 4em 0;
                }
            }
        }

        .count_container {
            .steps_strip {
                .step_wrap {
                    .count {
                        font-size: 1.4em;
                        width: 2em;
                        height: 2em;
                        margin: 0 0 5px;
                    }

                    .title {
                        font-size: 1.4em;
                    }
                }
            }
        }
    }
}