@import '../../common.scss';

.footer_sec {
    position: relative;
    width: 100%;
    overflow: hidden;

    .footer1 {
        border-top: 1px solid rgba($color: $color-000000, $alpha: 0.5);
        border-bottom: 1px solid rgba($color: $color-000000, $alpha: 0.5);
        background: $color-EAE5DF;


        .footer_flex {
            width: 100%;
            max-width: 81%;
            margin: 0 auto;
            display: flex;
            justify-content: center;

            .fcol {
                width: 100%;
                padding: 6.25em 0 5em;

                &.fcol-1,
                &.fcol-3 {
                    max-width: 28.5%;
                }

                &.fcol-2 {
                    max-width: 43%;
                    border-left: 1px solid rgba($color: $color-000000, $alpha: 0.5);
                    border-right: 1px solid rgba($color: $color-000000, $alpha: 0.5);
                    padding-left: 3.75em;
                    padding-right: 7.813em;
                }

                &.fcol-3 {
                    padding-left: 3.75em;
                }

                .soft_title {
                    @include textStyles(1.25em, 1.4em, 0);
                    color: $color-603413;
                    margin: 0 0 2em;
                }

                .desc {
                    @include textStyles(1.25em, 1.4em, 0);
                }

                .navlist {
                    .list_item {
                        position: relative;
                        display: block;
                        @include textStyles(2.5em, 1.2em, 0);
                        font-family: $KF;
                        margin: 0 0 0.5em;

                        &:last-child {
                            margin: 0;
                        }

                        &.active {
                            font-weight: 600;
                        }
                    }
                }

                .connect_list {
                    .list_item {
                        position: relative;
                        display: block;
                        margin: 0 0 1.5em;

                        .title {
                            font-weight: 700;
                            @include textStyles(1.25em, 1.4em, 0);
                        }

                        .anchor {
                            @include textStyles(1.25em, 1.4em, 0);
                        }

                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .footer2 {
        background: $color-EAE5DF;

        .copyright_footer {
            width: 100%;
            max-width: 81%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 1.5em 0;
            @include textStyles(1.25em, 1.4em, 0);
        }
    }

    .footer3 {
        position: relative;

        .bg_img {
            position: fixed;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 30em;
            bottom: 0;
            left: 0;
            z-index: -2;
        }

        .text_container {
            .cta_wrapper {
                min-height: 30em;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .footer_sec {
        .footer1 {
            .footer_flex {
                max-width: 100%;

                .fcol {
                    padding: 6em 0;

                    &.fcol-2 {
                        padding-right: 3em;
                        padding-left: 3em;
                    }

                    &.fcol-3 {
                        padding-left: 3em;
                    }

                    .soft_title {
                        font-size: 1.4em;
                        margin: 0 0 1.143em;
                    }

                    .desc {
                        font-size: 1.6em;
                    }

                    .navlist {
                        .list_item {
                            font-size: 3em;
                        }
                    }

                    .connect_list {
                        .list_item {

                            .title,
                            .anchor {
                                font-size: 1.6em;
                                word-break: break-all;
                            }
                        }
                    }
                }
            }
        }

        .footer2 {
            .copyright_footer {
                font-size: 1.4em;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .footer_sec {
        .my_container {
            padding: 0;
        }

        .footer1 {
            .footer_flex {
                flex-direction: column;

                .fcol {
                    width: 100%;
                    max-width: 100% !important;
                    padding: 2.5em 1.5em !important;

                    &.fcol-2 {
                        border: 0;
                        border-top: 1px solid rgba($color: $color-000000, $alpha: 0.5);
                        border-bottom: 1px solid rgba($color: $color-000000, $alpha: 0.5);
                    }

                    .desc {
                        font-size: 1.4em;
                        max-width: 95%;
                    }

                    .connect_list {
                        .list_item {

                            .title,
                            .anchor {
                                font-size: 1.4em;
                            }
                        }
                    }
                }
            }
        }

        .footer2 {
            .my_container {
                padding: 0 1.5em;
            }

            .copyright_footer {
                padding: 2.5em 0 3em;
                flex-direction: column;
                margin: 0;

                .legals {
                    margin: 0.714em 0;
                }
            }
        }
    }
}