@import "./common-mixins.scss";

$color-ffffff: #ffffff;
$color-D9D9D9: #D9D9D9;
$color-EFEFF5: #EFEFF5;
$color-EAE5DF: #EAE5DF;
$color-603413: #603413;
$color-311806: #311806;
$color-A67B5B: #A67B5B;
$color-000000: #000000;
$color-0A6365: #0A6365;
$color-707070: #707070;
$color-00000029: #00000029;

// Container size 
$container-80: 80%;
$container-94: 93.78%;
$container-992: 90%;


// FONT FAMILY
$KF: 'Kaftan Serif - Trial', sans-serif;
$DM: 'DM Sans', sans-serif;

body {
  font-family: $DM;
  color: $color-311806;
  background: $color-EAE5DF;
}

img {
  object-fit: cover;
  height: auto;
}

::-webkit-scrollbar {
  display: none;
  width: 8px;
  background-color: #545353;
}

::-webkit-scrollbar-thumb {
  background-color: $color-ffffff;
}

::selection {
  color: $color-ffffff;
  background: $color-0A6365;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px;
}

::-moz-focus-inner {
  border: 0;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

li {
  list-style-type: none;
}

[type="submit"],
button,
html [type="button"] {
  appearance: none;
  -webkit-appearance: none;
}

input [type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $color-000000 !important;
}

.form_input {
  @include inputtextStyle;

  &::-webkit-input-placeholder {
    color: $color-311806;
  }

  &:-ms-input-placeholder {
    color: $color-311806;
  }

  &::placeholder {
    color: $color-311806;
  }
}

.form_input:-webkit-autofill,
.form_input:-webkit-autofill:hover,
.form_input:-webkit-autofill:focus,
.form_input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

.firstsec {
  @include firstSec;
}

.heading_strip {
  display: none;
}

.form_field {
  position: relative;
  padding: 0 0 2.25em;

  .form_title {
    @include textStyles(1.25em, 1.1em, 0);
  }

  .error_message {
    position: absolute;
    font-size: 1em;
    color: red;
    bottom: 0.5em;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.form_submit_btn {
  @include ctaStyle;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    @include hoverStyle;

    .arrow_icon {
      right: 3em;
    }
  }

  .arrow_icon {
    @include arrowStyle;
  }
}

.common_cta {
  @include ctaStyle;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 1.781em;
  font-size: 1.25em;
  height: 3.25em;
  font-family: "Poppins", sans-serif;

  &:focus {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    display: flex;
    width: 100%;
    font-size: 1.5em;
    height: 2.8em;
    background-color: $color-0A6365;
    border: 1px solid $color-0A6365;
    border-radius: 11px;
    color: $color-ffffff;
    margin-top: 1.5em;
    font-weight: 700;
    position: relative;
    overflow: hidden;
    z-index: 2;
    cursor: pointer;
    transition: all 0.3s;
    @include ctaStyle;
  }

  &:hover {
    color: $color-ffffff;
    text-decoration: none;
    @include hoverStyle;

    .arrow_icon {
      right: 1em;
    }
  }

  .arrow_icon {
    @include arrowStyle;
    max-width: 1.5em;
    right: 2em;
  }
}

table {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.anchor {
  appearance: none;
  -webkit-appearance: none;
  color: $color-311806;
  text-decoration: none;
  max-width: fit-content;
  border-bottom: 1px solid rgba($color: $color-000000, $alpha: 0);

  &:hover {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid rgba($color: $color-000000, $alpha: 1);
  }
}


.language_drop {
  position: relative;

  .language_select {
    @include textStyles(1.125em, 1.111em, 0);
    color: $color-311806;
    background: transparent;
    box-shadow: none;
    outline: none;
    border: 1px solid $color-000000;
    border-radius: 5em;
    padding: 1em 2.25em 1em 1.25em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
  }

  .select_arow {
    width: 100%;
    max-width: 0.667em;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.25em;
    margin: auto 0;
    pointer-events: none;
  }

  .select_mask {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: $color-ffffff;
    width: 100%;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .lang_icon {
      width: 100%;
      max-width: 1.6em;
    }
  }

  &.light {
    .select_mask {
      background: transparent;
    }

    .language_select {
      color: $color-ffffff;
      border: 1px solid $color-ffffff;
      background: transparent;

      option {
        width: 100%;
        font-size: 1.25em;
        color: $color-000000;
      }
    }
  }
}



.my_container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background: transparent;
}

@media screen and (min-width: 2561px) {
  body {
    font-size: 18px;
  }

  .my_container {
    max-width: $container-80;
  }
}

@media screen and (max-width: 2560px) {
  body {
    font-size: 18px;
  }

  .my_container {
    max-width: $container-80;
  }
}

@media screen and (max-width: 1920px) {
  body {
    font-size: 16px;
  }

  .my_container {
    max-width: $container-94;
  }
}

@media screen and (max-width: 1700px) {
  body {
    font-size: 13px;
  }
}

@media screen and (max-width: 1620px) {
  body {
    font-size: 13px;
  }
}

@media screen and (max-width: 1536px) {
  body {
    font-size: 11px;
  }
}

@media screen and (max-width: 1280px) {
  body {
    font-size: 10px;
  }
}

@media screen and (max-width: 992px) {
  body {
    font-size: 10px;
  }

  .my_container {
    max-width: $container-992;
  }


  .language_drop {
    .language_select {
      border: none;
      padding: 1em 0;
      color: $color-ffffff;
      width: 2em;
    }

    .select_arow {
      display: none;
    }

    &.light {
      .select_mask {
        background: transparent;
      }

      .language_select {
        color: transparent;

      }
    }
  }

  .form_field {
    .form_title {
      font-size: 1.4em;
    }
  }
}

@media screen and (max-width: 768px) {
  .form_field {
    padding: 0 0 2em;

    .form_title {
      font-size: 1.4em;
    }
  }
}


@media screen and (max-width: 600px) {
  .my_container {
    max-width: 100%;
    padding: 0 15px;
  }

  .form_submit_btn {
    font-size: 1.4em;
    height: 3.5em;
  }

  .common_cta {
    font-size: 1.4em;
    height: 3.5em;
  }

  .sticky_booknow {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 10;

    .common_cta {
      font-size: 1.8em;
      background: transparent linear-gradient(90deg, #b20710 0%, #ee2c3c 100%) 0% 0% no-repeat padding-box;
      margin: 0;
      padding: 0;
      height: auto;
      text-align: center;
      border-radius: 0;
      height: 100%;
      min-height: 7.5vh;
      max-height: 7.5vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}