@import '../../common.scss';

.animated-cta {
    max-width: fit-content;
    display: flex;
    align-items: center;
    position: relative;
    color: $color-ffffff;
    text-decoration: none;
    border: none;
    border-radius: 5em;
    padding: 1.35em 1em;
    margin: 0 0 0 1em;
    background-color: $color-0A6365;
    overflow: hidden;

    .star,
    .star-inverse {
        max-width: 1.5em;
        transition: all 1s;
    }

    .content {
        position: relative;
        overflow: hidden;

        &.withstar {
            margin: 0 2em;
        }


        .text {
            transform: translateY(0%);
            color: $color-ffffff;
        }

        .hover-text {
            position: absolute;
            transition: transform 0.5s;
            visibility: hidden;
            bottom: -100%;
            color: $color-ffffff;
        }
    }

    &:hover {
        .content {
            .hover-text {
                visibility: visible;
                transform: translateY(-100%);
            }

            .text {
                visibility: hidden;
                transform: translateY(-100%);
            }
        }

        .star {
            transform: rotate(90deg);
        }

        .star-inverse {
            transform: rotate(-90deg);
        }
    }

    &.light {
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(0.5em);
        -webkit-backdrop-filter: blur(0.5em);
        padding: 1em 2em;
    }
}

.header_bookcta {
    background-color: $color-0A6365;
    padding: 1em 2em;

    &.light {
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(0.5em);
        -webkit-backdrop-filter: blur(0.5em);
        padding: 1em 2em;

    }
}


@media screen and (max-width: 992px) {
    .animated-cta {
        padding: 1.5em 2em;
        .content {

            .text,
            .hover-text {
                font-size: 1.4em;
            }
        }
    }
}