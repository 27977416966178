@import '../../common.scss';

.conditions_sec1 {
    padding: 20em 0 5.313em;
    background: $color-EAE5DF;

    .text_container {
        width: 100%;
        max-width: 66%;
        margin: 0 auto;

        .title {
            @include textStyles(3.75em, 1.25em, 0);
            font-family: $KF;
            margin: 0.1em 0 0.47em;
        }

        .subtitle {
            @include textStyles(2.5em, 1.4em, 0);
            font-family: $KF;
            color: $color-603413;
            margin: 0 0 .5em
        }

        .desc {
            @include textStyles(1.5em, 1.4em, 0);

            ul {
                padding: 0 0 0 2.5em;

                li {
                    position: relative;
                    line-height: 1.5em;
                    padding: 0 0 1em;
                    list-style-type: disc !important;

                    ul {

                        li {
                            padding: 1em 0 0;
                            list-style-type: circle !important;
                        }
                    }
                }
            }
        }

        .box {
            padding: 0 0 2.5em;
        }

    }
}

@media screen and (max-width: 992px) {
    .conditions_sec1 {
        padding: 20em 0 5.313em;
    
        .text_container {
            width: 100%;
            max-width: 80%;
    
            .title {
                @include textStyles(3.5em, 1.25em, 0);
                margin: 0.1em 0 1em;
            }
    
            .subtitle {
                @include textStyles(3em, 1.4em, 0);
            }
    
            .desc {
                @include textStyles(1.8em, 1.4em, 0);
            }
    
        }
    }
}

@media screen and (max-width: 600px) {
    .conditions_sec1 {
        padding: 15em 0 5.313em;
    
        .text_container {
            max-width: 100%;
    
            .title {
                @include textStyles(2.8em, 1.25em, 0);
                margin: 0.1em 0 1em;
            }
    
            .subtitle {
                @include textStyles(2.4em, 1.4em, 0);
            }
    
            .desc {
                @include textStyles(1.4em, 1.4em, 0);
            }
    
        }
    }
}