@import '../../common.scss';

.contact_sec1 {
    position: relative;
    background: $color-EAE5DF;

    .contact_bg {
        width: 100%;
    }

    .text_container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .title {
            @include textStyles(5em, 1.225em, 0);
            font-family: $KF;
            color: $color-ffffff;
        }
    }
}

.contact_sec2 {
    padding: 10.625em 0 11.438em;
    background: $color-EAE5DF;

    .title {
        @include textStyles(6.25em, 1.25em, 0);
        font-family: $KF;
        margin: 0 0 0.45em;
    }

    .form_flex {
        width: 100%;
        display: flex;
        margin: 0 0 6.625em;

        .form_box {
            width: 100%;
            max-width: 71.3%;
            padding: 0 12.5em 0 0;

            .thank_msg {
                @include textStyles(2em, 1.4em, 0);
                background: rgba($color: $color-0A6365, $alpha: 0.4);
                text-align: center;
                padding: 0.5em 1em;
                border-radius: 10px;
                margin: 2.5em 0 0;
                font-weight: 700;
            }

            .form_field {
                .form_title {
                    margin: 0 0 0.5em;
                }
            }

            .animated-cta {
                margin: 2em 0 0;
                cursor: pointer;
            }
        }

        .map_box {
            width: 100%;
            max-width: 28.7%;
            border-left: 1px solid rgba($color: $color-000000, $alpha: 0.4);
            padding: 0 0 0 2.625em;

            .text_container {
                .c_title {
                    @include textStyles(2.5em, 0.7em, 0);
                    font-weight: 700;
                }
            }

            .map_connect {
                padding: 2.5em 0 0;

                .list_item {
                    position: relative;
                    display: block;
                    margin: 0 0 1.625em;

                    .title {
                        @include textStyles(1.25em, 1.4em, 0);
                        font-family: $DM;
                        font-weight: 400;
                        margin: 0 0 0.25em
                    }

                    .anchor {
                        @include textStyles(1.25em, 1.4em, 0);
                    }
                }
            }

            .map_container {
                width: 100%;
                max-width: 100%;
                aspect-ratio: 16/9;
                background: $color-ffffff;
                border-radius: 2.5em;
                overflow: hidden;

                .map_frame {
                    border: 0;

                    &:active,
                    &:hover {
                        border: 0;
                    }
                }
            }
        }
    }

    .invite_container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .invite_title {
            @include textStyles(6.25em, 1.25em, 0);
            max-width: 80%;
            margin: 0 auto;
            font-family: $KF;
            text-align: center;
        }

        .verticle_line {
            height: 87px;
            margin: 0.313em 0 1.875em;
        }
    }
}

@media screen and (max-width: 1024px) {
    .contact_sec2 {
        .title {
            font-size: 5em;
            margin: 0 0 0.55em;
        }

        .invite_container {
            .invite_title {
                font-size: 5em;
                max-width: 100%;
            }

            .verticle_line {
                margin: 1em 0 3em;
            }
        }

        .form_flex {
            .form_box {
                max-width: 65%;
                padding: 0 5em 0 0;
            }

            .map_box {
                max-width: 35%;

                .text_container {
                    .c_title {
                        font-size: 3em;
                    }
                }

                .map_connect {
                    .list_item {
                        .title {
                            font-size: 2em;
                        }

                        .anchor {
                            font-size: 1.8em;
                        }
                    }
                }

                .map_container {
                    margin: 4em 0 0;
                }
            }
        }
    }
}

@media screen and (max-width: 786px) {
    .contact_sec2 {
        .form_flex {
            flex-direction: column;

            .form_box {
                max-width: 100%;
                padding: 0 0 7em;

                .animated-cta {
                    margin: 0;
                }
            }

            .map_box {
                max-width: 100%;
                border: none;
                border-top: 1px solid rgba($color: $color-000000, $alpha: 0.4);
                padding: 7em 0 0;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .contact_sec1 {
        .contact_bg {
            aspect-ratio: 4/6;
        }
    }

    .contact_sec2 {
        padding: 5.7em 0 7.4em;

        .title {
            font-size: 3.6em;
            margin: 0 0 0.75em;
        }

        .form_flex {
            margin: 0 0 6em;

            .map_box {
                .map_connect {
                    padding: 2.1em 0 0;

                    .list_item {
                        margin: 0 0 1.6em;

                        .title,
                        .anchor {
                            font-size: 1.4em;
                        }
                    }
                }

                .map_container {
                    max-width: 100%;
                    border-radius: 4.1em;
                    margin: 2.1em 0 0;
                }
            }
        }

        .invite_container {
            .invite_title {
                max-width: 95%;
                margin: 0 auto;
                font-size: 3.6em;
            }

            .verticle_line {
                margin: 2.5em 0 2em;
            }
        }
    }
}